<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="editedItem.ParentTaskID"
        item-text="Task"
        item-value="TaskID"
        :items="TaskList"
        label="Mor oppgave"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.TaskStatusID"
        item-text="Description"
        item-value="Value"
        :items="TaskStatusItems"
        label="Status"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.TaskTypeID"
        item-text="Description"
        item-value="Value"
        :items="TaskTypeItems"
        label="Oppgave type"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.BrukerID"
        item-text="Description"
        item-value="Value"
        :items="BrukerItems"
        label="Bruker"
      ></v-autocomplete>
      <v-text-field
        v-model="editedItem.Task"
        label="Oppgave"
        maxlength="120"
      ></v-text-field>
      <v-text-field
        v-model.number="editedItem.ProsentFerdig"
        label="Prosent ferdig"
        hide-details
        single-line
        type="number"
      ></v-text-field>
      <wtDatetime v-model="editedItem.Tidsfrist" label="Tidsfrist"></wtDatetime>
      <wtDatetime v-model="editedItem.Start" label="Start"></wtDatetime>
      <wtDatetime v-model="editedItem.Slutt" label="Slutt"></wtDatetime>
      <v-textarea
        v-model="editedItem.Kommentar"
        filled
        label="Kommentar"
        auto-grow
      ></v-textarea>
      <v-text-field
        v-model.number="editedItem.EstimertTid"
        label="Estimert tid"
        hide-details
        single-line
        type="number"
      ></v-text-field>
      <v-text-field
        v-model.number="editedItem.EstimertKost"
        label="Estimert kostnad"
        hide-details
        single-line
        type="number"
      ></v-text-field>
      <wtMultiSelect
        v-model="editedItem.itemsAvhengighet"
        item-text="Task"
        item-value="TaskID"
        :items="TaskList"
        label="Avhengig av"
      ></wtMultiSelect>
      <wtMultiSelect
        v-model="editedItem.itemsSakTask"
        item-text="Description"
        item-value="Value"
        :items="SakItems"
        label="SakTask"
      ></wtMultiSelect>
      <wtMultiSelect
        v-model="editedItem.itemsTaskVedlegg"
        item-text="Description"
        item-value="Value"
        :items="VedleggMetaItems"
        label="TaskVedlegg"
      ></wtMultiSelect>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";
import wtDatetime from "../components/wtDatetime.vue";
import wtMultiSelect from "../components/wtMultiSelect.vue";

export default {
  extends: KeyValue,
  name: "dlgTask",
  components: {
    wtDatetime,
    wtMultiSelect,
  },

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    TaskList: Array,
    keyProsjektID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },
  
  created() {
    this.GetBrukerCode();
    this.GetTaskStatusCode();
    this.GetTaskTypeCode();
    //this.GetIkke Match - AvhengigAvTaskIDCode();
    //this.GetIkke Match - AvhengigAvTaskIDCode();
    this.GetSakCode();
    this.GetVedleggMetaCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
